@tailwind components;

@layer components {
  .category {
    @apply flex w-full justify-between overflow-x-auto;
  }

  .category-item {
    @apply mr-6 cursor-pointer whitespace-nowrap rounded-button bg-primary-gradient p-1 last:mr-0;
  }

  .category-item span {
    @apply relative isolate h-full w-full rounded-[inherit] bg-black px-8 py-3 font-semibold text-white;
  }

  .category-item:hover span {
    @apply before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-0 before:rounded-[inherit] before:bg-primary-gradient before:transition-[width] before:duration-200 before:ease-in hover:before:w-full;
  }

  .category-activated-item span {
    @apply bg-primary-gradient;
  }
}
