@tailwind components;

@layer components {
  .not-found {
    @apply bg-not-found-background-img h-screen w-screen bg-cover bg-center bg-no-repeat;
  }

  .not-found-content-container {
    @apply flex h-full items-center justify-center;
  }

  .not-found-img {
    @apply h-[40%] w-[35%] shrink-0;
  }

  .not-found-content {
    @apply flex flex-col items-start font-black;
  }

  .not-found-title {
    @apply text-8xl text-white;
  }

  .not-found-sub-title {
    @apply mt-4 text-5xl uppercase text-white;
  }

  .not-found-text {
    @apply text-3xl leading-5;
  }

  .not-found-btn {
    @apply bg-primary-color mt-8 px-12 text-3xl;
  }
}
