@tailwind components;

@layer components {
  .header {
    @apply border-border-color flex w-full items-center justify-between border-b-[0.1rem] lg:pr-24;
  }

  .header-home-btn {
    @apply justify-start;
  }
  .header-home-btn img {
    @apply w-[80%];
  }

  .header-nav-bar {
    @apply xs:hidden lg:flex;
  }

  .header-right-side {
    @apply items-center xs:hidden lg:flex;
  }

  .header-flag {
    @apply mr-4;
  }

  .header-phone {
    @apply relative flex items-center;
  }

  .header-phone-img {
    @apply absolute right-[-5rem];
  }

  .header-btn {
    @apply bg-primary-gradient;
  }
}
