@tailwind components;

@layer components {
  .footer-section {
    @apply bg-footer-gradient text-white;
  }

  .footer-section-text {
    @apply pb-6 first:pb-1 last:pb-0;
  }
}
