@tailwind components;

@layer components {
  .transparent-btn {
    @apply rounded-button bg-primary-gradient p-1;
  }

  .transparent-text-btn {
    @apply relative isolate h-full w-full rounded-[inherit] bg-black px-8 py-3 text-center font-semibold text-white before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-0 before:rounded-[inherit] before:bg-primary-gradient before:transition-[width] before:duration-200 before:ease-in hover:before:w-full;
  }
}
