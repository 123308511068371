@tailwind components;

@layer components {
  .footer-item {
    @apply mb-5;
  }

  .footer-item span {
    @apply text-left;
  }
}
