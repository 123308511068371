@tailwind components;

@layer components {
  .header-content {
    @apply justify-between pb-10 md:flex;
  }

  .header-content-title {
    @apply text-[5rem] font-semibold leading-[7rem];
  }

  .header-content-gradient-title {
    @apply bg-yellow-gradient bg-clip-text text-transparent;
  }

  .header-content-text {
    @apply my-20 text-blur-text-color;
  }

  .header-content-btn-container {
    @apply flex;
  }

  .header-content-btn-container button {
    @apply first:mr-8;
  }

  .header-content-side {
    @apply md:w-[45%];
  }

  .header-content-right-side {
    @apply relative flex flex-col items-center;
  }

  .header-content-image {
    @apply h-[40rem] xs:mt-8 md:mt-0;
  }

  .header-content-info-number {
    @apply relative;
  }

  .header-content-list {
    @apply absolute top-[25%] flex w-full justify-around;
  }

  .header-content-item {
    @apply relative flex flex-col items-center [&:nth-child(2)]:border-l-[0.1rem] [&:nth-child(2)]:border-r-[0.1rem] [&:nth-child(2)]:border-white [&:nth-child(2)]:px-[10%];
  }

  .header-content-number {
    @apply font-semibold text-purple xs:text-4xl sm:text-6xl;
  }

  .header-content-label {
    @apply xs:text-xl sm:text-2xl;
  }

  .header-content-btn {
    @apply bg-primary-gradient;
  }
}
