@tailwind components;

@layer components {
  .part-four {
    @apply grid gap-8 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3;
  }

  .part-four-title-container {
    @apply flex items-center;
  }

  .part-four-title-container img {
    @apply ml-8;
  }

  .part-four-list {
    @apply mt-5;
  }

  .part-four-item {
    @apply mb-5 flex;
  }

  .part-four-title {
    @apply mb-4 flex;
  }

  .part-four-image {
    @apply ml-4 w-[5rem];
  }

  .part-four-text-container {
    @apply ml-2 flex flex-1 flex-col;
  }

  .part-four-icon-container {
    @apply flex;
  }

  .part-four-icon {
    @apply mr-6 h-12 w-12;
  }

  .part-four-copyright-container {
    @apply justify-between;
  }

  .part-four-term {
    @apply my-8 flex;
  }

  .part-four-separator {
    @apply mx-4;
  }
}
