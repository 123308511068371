@tailwind components;

@layer components {
  .modal {
    @apply fixed bottom-0 left-0 right-0 top-0 z-[991] flex items-center justify-center bg-blur-background-color;
  }

  .modal-content-container {
    @apply relative max-h-[70%] w-[70rem] overflow-hidden rounded-[1rem] bg-modal-background-img bg-cover bg-center bg-no-repeat px-8 py-4 text-white;
  }

  .modal-content {
    @apply h-full overflow-scroll pt-24;
  }

  .modal-header {
    @apply absolute left-0 top-0 z-20 flex w-full items-center justify-center border-b-[0.1rem] border-white px-8 py-4 text-4xl;
  }

  .modal-title {
    @apply font-bold;
  }

  .modal-close-btn {
    @apply absolute right-[2%] top-[25%] text-4xl;
  }

  .modal-opacity {
    @apply animate-opacity-fade-in;
  }

  .modal-fade-in {
    @apply animate-fade-in;
  }
}
