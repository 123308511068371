@tailwind components;

@layer components {
  .icon-button {
    @apply flex items-center justify-center;
  }

  .icon-button-text {
    @apply flex-1 text-center text-white;
  }
}
