@tailwind components;

@layer components {
  .scroll-to-top-button {
    @apply fixed bottom-4 right-4 z-[888] animate-opacity-fade-in will-change-[opacity] animate-fill-forwards;
  }

  .scroll-to-top-button-btn {
    @apply text-dark-sky animate-btn-fade-in p-0 text-[4rem] leading-[0] will-change-transform;
  }

  .scroll-to-top-button .hide-btn {
    @apply animate-btn-fade-out;
  }

  .hide-scroll-to-top-button {
    @apply animate-opacity-fade-out animate-fill-forwards;
  }
}
