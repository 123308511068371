@tailwind components;

@layer components {
  .faq {
    @apply flex flex-col items-center bg-[#725bdb33] py-[10rem];
  }

  .faq-title-container {
    @apply mb-20 flex flex-col items-center;
  }

  .faq-title {
    @apply mb-8 text-center text-5xl font-semibold;
  }

  .faq-text {
    @apply text-blur-text-color w-[80%] text-center;
  }

  .faq-list {
    @apply flex w-[80%] flex-wrap items-start justify-between xs:flex-col md:flex-row;
  }

  .faq-item {
    @apply border-border-color mb-8 flex flex-col items-start rounded-[2rem] border-[0.1rem] p-8 md:w-[49%];
  }

  .faq-item div {
    @apply flex items-center font-semibold;
  }

  .faq-item > span {
    @apply mt-4;
  }

  .faq-item i {
    @apply mr-4;
  }

  .faq-item span {
    @apply text-left;
  }
}
