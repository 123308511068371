@tailwind components;

@layer components {
  .input-container {
    @apply flex items-center w-full p-4 pt-5 overflow-hidden;
  }

  .input-component {
    @apply flex items-center pr-4 bg-transparent placeholder:text-2xl;
  }

  .input-icon-container {
    @apply flex items-center translate-y-[0.1rem];
  }

  .input-icon {
    @apply w-8;
  }

  .left-icon-container {
    @apply justify-start;
  }

  .right-icon-container {
    @apply cursor-pointer;
  }
}
