@tailwind components;

@layer components {
  .mobile-menu {
    @apply lg:hidden;
  }

  .mobile-menu-btn {
    @apply text-4xl text-white;
  }

  .ant-drawer-content.mobile-menu-content {
    @apply bg-mobile-menu-background-img bg-no-repeat;
  }

  .mobile-menu-content .ant-drawer-header {
    @apply hidden;
  }

  .mobile-menu-nav-bar {
    @apply w-full flex-col;
  }

  .mobile-menu-nav-bar .nav-item {
    @apply my-8 text-[1.6rem];
  }

  .mobile-menu-phone {
    @apply relative mt-12 flex flex-col items-center;
  }

  .mobile-menu-phone-img {
    @apply mt-8;
  }

  .mobile-menu-phone-btn {
    @apply bg-primary-gradient;
  }

  .mobile-menu-hidden {
    @apply hidden;
  }
}
