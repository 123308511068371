@tailwind components;

@layer components {
  .subscribe {
    @apply bg-subscribe-color my-4 flex flex-col items-center rounded-[2rem] py-[10rem];
  }

  .subscribe-title-container {
    @apply flex flex-col items-center xs:mb-12 md:mb-20;
  }

  .subscribe-title {
    @apply mb-8 text-center text-5xl font-semibold leading-normal;
  }

  .subscribe-text {
    @apply text-blur-text-color text-center;
  }

  .subscribe-input-container {
    @apply flex flex-1 items-center xs:w-full xs:flex-col md:w-1/2 md:flex-row;
  }

  .subscribe-input {
    @apply bg-subscribe-color h-full rounded-full xs:mb-6 md:mb-0;
  }

  .subscribe-btn {
    @apply ml-8 whitespace-nowrap bg-primary-gradient;
  }
}
