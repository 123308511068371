@tailwind components;

@layer components {
  .button {
    @apply flex items-center justify-center;
  }

  .button-text {
    @apply flex-1 text-center text-white;
  }
}
