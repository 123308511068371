@tailwind components;

@layer components {
  .brief-card {
    @apply bg-subscribe-color flex flex-col items-center rounded-[2rem] p-4;
  }

  .brief-card:hover {
    @apply bg-subscribe-color scale-105 cursor-pointer rounded-[2rem] border-[0.1rem] border-white p-4 transition-all duration-300 ease-in;
  }

  .brief-card-text-container {
    @apply my-4 w-full;
  }

  .brief-card-label-and-text {
    @apply flex justify-between;
  }

  .brief-card-label {
    @apply text-3xl font-semibold;
  }

  .brief-card-text {
    @apply mb-5 bg-yellow-gradient bg-clip-text text-3xl font-semibold text-transparent;
  }

  .brief-card-time {
    @apply text-blur-text-color;
  }

  .brief-card-btn {
    @apply invisible mt-8 w-full rounded-[1.2rem] bg-primary-gradient;
  }

  .brief-card:hover .brief-card-btn {
    @apply visible;
  }
}
