@tailwind components;

@layer components {
  .loading {
    @apply bg-blur-background-color absolute left-0 top-0 z-[999] flex h-screen w-screen items-center justify-center;
  }

  .loading-img-container {
    @apply relative flex size-[8rem] items-center justify-center rounded-full p-4 after:absolute after:left-0 after:top-0 after:size-full after:animate-loading after:rounded-[inherit] after:border-[0.4rem] after:border-transparent after:border-r-green;
  }

  .loading-img {
    @apply w-[90%];
  }
}
