@tailwind components;

@layer components {
  .brief-projects-list {
    @apply my-20 flex flex-col items-center last:mb-0;
  }

  .brief-projects-list-title-container {
    @apply flex justify-between xs:flex-col md:flex-row;
  }

  .brief-projects-list-text.brief-projects-list-title {
    @apply text-7xl font-semibold text-white xs:mb-8 md:mb-0;
  }

  .brief-projects-list-text {
    @apply text-blur-text-color md:w-[45%];
  }

  .brief-projects-list-category {
    @apply my-16;
  }

  .brief-projects-list-btn {
    @apply mt-8;
  }
}
