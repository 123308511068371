@tailwind components;

@layer components {
  .general-container {
    @apply flex w-screen flex-col justify-start overflow-x-hidden bg-primary-background-img bg-cover bg-center bg-no-repeat text-white;
  }

  .general-content {
    @apply flex flex-col justify-between p-vertical text-white;
  }

  .general-padding {
    @apply px-vertical py-8;
  }

  .general-list {
    @apply m-0 list-none p-0;
  }

  .general-btn {
    @apply rounded-button px-8 py-4 font-semibold text-white;
  }

  .general-title {
    @apply font-semibold text-white;
  }

  .general-gradient-title {
    @apply mb-5 bg-text-gradient bg-clip-text font-bold text-transparent;
  }

  .general-text {
    @apply text-white;
  }
}
