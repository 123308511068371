@tailwind components;

@layer components {
  .nav-bar {
    @apply flex w-[50rem] justify-between;
  }

  .nav-item {
    @apply relative font-semibold text-white;
  }

  .cost-and-policy {
    @apply w-[30%];
  }

  .nav-item--activated {
    @apply before:absolute before:bottom-[-1rem] before:left-0 before:h-[0.3rem] before:w-full before:bg-white;
  }
}
