@tailwind components;

@layer components {
  .part-three {
    @apply border-border-color border-b-2;
  }

  .part-three-section-one {
    @apply grid xs:mb-12 xs:gap-12 sm:grid-cols-2 md:grid-cols-3 lg:mb-0 lg:grid-cols-4 lg:gap-20;
  }

  .part-three-section-two {
    @apply flex items-center justify-between xs:flex-col lg:flex-row;
  }

  .part-three-section-two > div {
    @apply flex items-start justify-between xs:flex-col sm:flex-row;
  }

  .part-three-list {
    @apply w-1/2 xs:my-12 sm:my-0;
  }

  .part-three-item {
    @apply mb-5 flex;
  }

  .part-three-text-container {
    @apply ml-2 flex-1;
  }

  .part-three-text-container a span {
    @apply text-left;
  }
}
